import dynamic from 'next/dynamic';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import React from 'react';
import type { SubscriptionActionVariant } from '../../../types/enums';
import type { ContextNodeFragment, NodeViewFragment } from '../../../types/graphql-types';

export interface Props {
  /**
   * The node for which subscription/membership action needed.
   */
  node: NodeViewFragment | ContextNodeFragment;
  /**
   * View variant.
   */
  variant?: SubscriptionActionVariant;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Whether to use a toast when success or not.
   */
  useSuccessFeedback?: boolean;
  /**
   * Whether to use an effect when hovering over the button or not.
   */
  useHoverEffect?: boolean;
  /**
   * Whether to use divider before when using Dropdown item variant.
   */
  useDivider?: boolean;
  /**
   * Function executed after a subscription delete is completed.
   */
  onSubscriptionDeleteCompleted?: () => void;
}

const NodeSubscriptionActionMain = dynamic(() => import('./NodeSubscriptionActionMain'), {
  ssr: false
});

/**
 * Node subscription/membership action component that handle different variants.
 *
 * @author Subhiksha Venkatesan, Martin Sandoval
 */
const NodeSubscriptionAction: React.FC<React.PropsWithChildren<Props>> = props => {
  const { isAnonymous } = useRegistrationStatus();
  if (isAnonymous) {
    return null;
  } else {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <NodeSubscriptionActionMain {...props} />;
  }
};

export default NodeSubscriptionAction;
